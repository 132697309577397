/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger";
// import "./typography.css";

if (typeof window !== "undefined") {
gsap.registerPlugin(ScrollTrigger);
}