exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-artoteka-js": () => import("./../../../src/templates/artoteka.js" /* webpackChunkName: "component---src-templates-artoteka-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-kontakt-js": () => import("./../../../src/templates/kontakt.js" /* webpackChunkName: "component---src-templates-kontakt-js" */),
  "component---src-templates-okruzenje-js": () => import("./../../../src/templates/okruzenje.js" /* webpackChunkName: "component---src-templates-okruzenje-js" */),
  "component---src-templates-plan-js": () => import("./../../../src/templates/plan.js" /* webpackChunkName: "component---src-templates-plan-js" */),
  "component---src-templates-projekat-js": () => import("./../../../src/templates/projekat.js" /* webpackChunkName: "component---src-templates-projekat-js" */),
  "component---src-templates-vest-js": () => import("./../../../src/templates/vest.js" /* webpackChunkName: "component---src-templates-vest-js" */),
  "component---src-templates-vesti-js": () => import("./../../../src/templates/vesti.js" /* webpackChunkName: "component---src-templates-vesti-js" */)
}

